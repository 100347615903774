import { createEventHook, whenever } from '@vueuse/core';
import type {
  TawkAgentJoinChat,
  TawkAgentLeaveChat,
  TawkApi,
  TawkFormField,
  TawkMessage,
  TawkOfflineSubmit,
  TawkSatisfaction,
  TawkStatus,
  TawkTagsUpdate,
} from '~/types/tawk';

declare global {
  interface Window {
    Tawk_API: TawkApi;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:tawk',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const userStore = useUserStore();
    const config = useRuntimeConfig().public.tawk;
    const { $bus } = useNuxtApp();
    const { join } = useFormatting();

    const enabled = config.enabled && !!config.account && !!config.widget;

    const onBeforeLoad = createEventHook();
    const onLoad = createEventHook();
    const onStatusChange = createEventHook<TawkStatus>();
    const onChatMaximized = createEventHook();
    const onChatMinimized = createEventHook();
    const onChatHidden = createEventHook();
    const onChatStarted = createEventHook();
    const onChatEnded = createEventHook();
    const onPrechatSubmit = createEventHook<TawkFormField[]>();
    const onOfflineSubmit = createEventHook<TawkOfflineSubmit>();
    const onChatMessageVisitor = createEventHook<TawkMessage>();
    const onChatMessageAgent = createEventHook<TawkMessage>();
    const onChatMessageSystem = createEventHook<TawkMessage>();
    const onAgentJoinChat = createEventHook<TawkAgentJoinChat>();
    const onAgentLeaveChat = createEventHook<TawkAgentLeaveChat>();
    const onChatSatisfaction = createEventHook<TawkSatisfaction>();
    const onVisitorNameChanged = createEventHook<string>();
    const onFileUpload = createEventHook<string>();
    const onTagsUpdated = createEventHook<TawkTagsUpdate>();

    const ready = ref(false);
    const status = ref<TawkStatus>('offline');

    const { load, maximize, minimize, toggle } = useScript(
      `https://embed.tawk.to/${config.account}/${config.widget}`,
      {
        trigger: 'manual',
        mode: 'client',
        beforeInit: () => {
          if (import.meta.server) {
            return;
          }

          window.Tawk_API = window.Tawk_API || {};
          window.Tawk_API.onLoad = onLoad.trigger;
          window.Tawk_API.onBeforeLoad = onBeforeLoad.trigger;
          window.Tawk_API.onStatusChange = onStatusChange.trigger;
          window.Tawk_API.onChatMaximized = onChatMaximized.trigger;
          window.Tawk_API.onChatMinimized = onChatMinimized.trigger;
          window.Tawk_API.onChatHidden = onChatHidden.trigger;
          window.Tawk_API.onChatStarted = onChatStarted.trigger;
          window.Tawk_API.onChatEnded = onChatEnded.trigger;
          window.Tawk_API.onPrechatSubmit = onPrechatSubmit.trigger;
          window.Tawk_API.onOfflineSubmit = onOfflineSubmit.trigger;
          window.Tawk_API.onChatMessageVisitor = onChatMessageVisitor.trigger;
          window.Tawk_API.onChatMessageAgent = onChatMessageAgent.trigger;
          window.Tawk_API.onChatMessageSystem = onChatMessageSystem.trigger;
          window.Tawk_API.onAgentJoinChat = onAgentJoinChat.trigger;
          window.Tawk_API.onAgentLeaveChat = onAgentLeaveChat.trigger;
          window.Tawk_API.onChatSatisfaction = onChatSatisfaction.trigger;
          window.Tawk_API.onVisitorNameChanged = onVisitorNameChanged.trigger;
          window.Tawk_API.onFileUpload = onFileUpload.trigger;
          window.Tawk_API.onTagsUpdated = onTagsUpdated.trigger;
        },
        use: () => window.Tawk_API,
      });

    onLoad.on(() => ready.value = true);
    onStatusChange.on(value => status.value = value);

    $bus.once('app:interaction', () => !enabled || load());

    whenever(
      () => !!userStore.data && ready.value,
      () => {
        const data = userStore.data!;

        window.Tawk_API.setAttributes({
          id: data.id,
          name: join(data.firstName, data.lastName),
          email: data.email,
        });
      },
    );

    return {
      provide: {
        tawk: {
          enabled,
          ready,
          status,
          maximize,
          minimize,
          toggle,
          onLoad: onLoad.on,
          onBeforeLoad: onBeforeLoad.on,
          onStatusChange: onStatusChange.on,
          onChatMaximized: onChatMaximized.on,
          onChatMinimized: onChatMinimized.on,
          onChatHidden: onChatHidden.on,
          onChatStarted: onChatStarted.on,
          onChatEnded: onChatEnded.on,
          onPrechatSubmit: onPrechatSubmit.on,
          onOfflineSubmit: onOfflineSubmit.on,
          onChatMessageVisitor: onChatMessageVisitor.on,
          onChatMessageAgent: onChatMessageAgent.on,
          onChatMessageSystem: onChatMessageSystem.on,
          onAgentJoinChat: onAgentJoinChat.on,
          onAgentLeaveChat: onAgentLeaveChat.on,
          onChatSatisfaction: onChatSatisfaction.on,
          onVisitorNameChanged: onVisitorNameChanged.on,
          onFileUpload: onFileUpload.on,
          onTagsUpdated: onTagsUpdated.on,
        },
      },
    };
  },
});
