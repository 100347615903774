<template>
  <div>
    <NuxtLoadingIndicator
      color="#ed1c24"
      :height="5"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { LazyDialogAgeGate } from '#components';

const ageStore = useAgeStore();
const { $bus } = useNuxtApp();
const { dialog } = useQuasar();

$bus.once('app:interaction', () => ageStore.check(18) || dialog({
  component: LazyDialogAgeGate,
  componentProps: {
    age: 18,
  },
}));

useSchemaOrg([
  defineLocalBusiness({
    name: 'KYGUNCO',
    address: {
      streetAddress: '401 Glenwood Drive',
      addressLocality: 'Bardstown',
      addressRegion: 'KY',
      postalCode: '40004',
      addressCountry: 'US',
    },
    telephone: '+1 (502) 348-3594',
    email: 'info@kygunco.com',
    openingHoursSpecification: {
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      opens: '09:00',
      closes: '18:00',
    },
  }),
]);
</script>
