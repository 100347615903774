import type { FetchError } from 'ofetch';
import type { ProblemDetails,
  CartItemResponse,
  OrderResponse,
  ProductResponse,
  VariationResponse,
} from '~/types/ecommerce';

declare module '~/plugins/bus' {
  export interface Events {
    'product:view': (product: ProductResponse, variation?: VariationResponse) => void;
    'product:add': (product: ProductResponse, variation?: VariationResponse, quantity?: number) => void;
    'cart:remove': (items: CartItemResponse[]) => void;
    'auth:register': () => void;
    'auth:login': () => void;
    'auth:logout': () => void;
    'checkout:start': (items: CartItemResponse[]) => void;
    'checkout:cancel': (items: CartItemResponse[]) => void;
    'checkout:complete': (payload: OrderResponse[]) => void;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:ecommerce',
  dependsOn: [],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig();

    const fetch = $fetch.create({
      baseURL:
      config.public.ecommerce.api.url
      ?? 'https://api.keenesdepot.io/kygunco.com',
      retry: 5,
      retryDelay: 1000,
      retryStatusCodes: [429],
      onRequest: async ({ options }) => {
        const authStore = useAuthStore();

        const headers = new Headers(options.headers);

        const _ = authStore.loggedIn
          ? headers.set('Authorization', `Bearer ${authStore.accessToken}`)
          : headers.delete('Authorization');

        options.headers = headers;
      },
    });

    const handle = (response: FetchError<ProblemDetails>) => {
      if (!response.data) {
        Notify.create({
          type: 'negative',
          message: 'An unknown error has occurred while processing your request.',
        });
        return;
      }

      if (!response.data.errors) {
        Notify.create({ type: 'negative', message: response.data.title });
        return;
      }

      for (const prop in response.data.errors) {
        (response.data.errors[prop] ?? []).forEach((m: string) =>
          Notify.create({ type: 'negative', message: m }),
        );
      }
    };

    return {
      provide: {
        ecommerce: {
          fetch,
          handle,
        },
      },
    };
  },
});
