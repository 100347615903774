import { createEventHook, useMemoize } from '@vueuse/core';
import type { Crdv, LowestMonthlyPayment } from '~/types/credova';

declare global {
  interface Window {
    CRDV: Crdv;
  }
}

declare module '~/plugins/bus' {
  export interface Events {
    'credova:checkout:start': (publidId: string) => void;
    'credova:checkout:cancel': (publidId: string) => void;
    'credova:checkout:complete': (publidId: string) => void;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:credova',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const { $bus } = useNuxtApp();
    const runtimeConfig = useRuntimeConfig().public.credova;

    const enabled = runtimeConfig.enabled;
    const minAmount = runtimeConfig.minAmount ?? 300;
    const maxAmount = runtimeConfig.maxAmount ?? 10000;

    const { status, load, onLoaded } = useScript(
      {
        src: 'https://plugin.credova.com/plugin.min.js',
        referrerpolicy: false,
        crossorigin: false,
      },
      {
        trigger: 'manual',
        mode: 'client',
        use: () => window.CRDV,
      });

    onLoaded(({ plugin }) => plugin.config({
      environment: window.CRDV.Environment[runtimeConfig.env]!,
      store: runtimeConfig.store,
      minAmount,
      maxAmount,
    }));

    const ready = computed(() => status.value == 'loaded');

    const valid = (amount: number) => enabled && amount >= minAmount && amount <= maxAmount;

    const getLowestMonthlyPayment = useMemoize((amount: number) => $fetch<LowestMonthlyPayment>(
      `/store/${runtimeConfig.store}/amount/${amount}/lowestMonthlyPayment`,
      {
        baseURL: 'https://lending-api.credova.com/v2/calculator',
        method: 'POST',
      }));

    const onCheckoutStart = createEventHook<string>();
    const onCheckoutCancel = createEventHook<string>();
    const onCheckoutComplete = createEventHook<string>();

    const prequalify = (amount = minAmount) => load().then(({ plugin }) =>
      plugin.prequalify(amount));

    const checkout = (publicId: string) => load().then(async ({ plugin }) => {
      onCheckoutStart.trigger(publicId);

      const complete = await plugin.checkout(publicId);

      const _ = complete
        ? onCheckoutComplete.trigger(publicId)
        : onCheckoutCancel.trigger(publicId);
    });

    onCheckoutStart.on(publicId => $bus.emit('credova:checkout:start', publicId));
    onCheckoutCancel.on(publicId => $bus.emit('credova:checkout:cancel', publicId));
    onCheckoutComplete.on(publicId => $bus.emit('credova:checkout:complete', publicId));

    return {
      provide: {
        credova: {
          enabled,
          ready,
          valid,
          minAmount,
          maxAmount,
          getLowestMonthlyPayment,
          prequalify,
          checkout,
          onCheckoutStart: onCheckoutStart.on,
          onCheckoutCancel: onCheckoutCancel.on,
          onCheckoutComplete: onCheckoutComplete.on,
        },
      },
    };
  },
});
