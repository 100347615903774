import { EventBus } from 'quasar';

export interface Events {
  'app:interaction': (e: Event) => void;
};

type MappedEvents = {
  [key in keyof Events]: Events[key];
};

export default defineNuxtPlugin({
  name: 'kygunco:bus',
  parallel: true,
  setup: () => {
    const bus = new EventBus<MappedEvents>();

    /**
     * This will perform a lot better than using onInteraction() composable for first interaction.
     * For any other use case just use onInteraction() directly wherever it's needed.
     *
     * Note: For first interaction detection subscribe with "once":
     * $bus.once('app:interaction', (e) => {});
     */
    onInteraction((e: Event) => bus.emit('app:interaction', e));

    return {
      provide: {
        bus,
      },
    };
  },
});
