import type { Product } from '~/types/search';
import type { SearchParams, SearchParamsWithPreset, SearchResponse } from '~/types/typesense';

declare module '~/plugins/bus' {
  export interface Events {
    'catalog:add': (product: Product, collection?: string) => void;
    'catalog:search': (term: string) => void;
    'catalog:select': (product: Product, collection?: string) => void;
    'catalog:view': (products: Product[], collection?: string) => void;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:typesense',
  dependsOn: [],
  parallel: true,
  order: 0,
  setup: () => {
    const config = useRuntimeConfig();

    const fetch = $fetch.create({
      baseURL: config.public.typesense.url ?? 'https://search.keenesdepot.io',
      onRequest: async ({ options }) => {
        const headers = new Headers(options.headers);
        headers.set('x-typesense-api-key', config.public.typesense.apiKey);
        options.headers = headers;
      },
    });

    const search = <T>(collection: string, params: SearchParams | SearchParamsWithPreset) => {
      return fetch<SearchResponse<T>>(`collections/${collection}/documents/search`, { params });
    };

    const first = async <T>(
      collection: string,
      params: SearchParams | SearchParamsWithPreset,
      fail?: boolean) => {
      const response = await search<T>(collection, { ...params, limit: 1 });

      if (!response.hits?.[0]?.document && fail) {
        throw createError({ status: 404, statusMessage: 'Not Found' });
      }

      return response.hits?.[0]?.document;
    };

    return {
      provide: {
        typesense: {
          fetch,
          search,
          first,
        },
      },
    };
  },
});
